// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()



var jQuery = require("jquery");

global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;

$(document).on("turbolinks:load", function() {

  var day = parseInt($(".template-day").val()) || 0;

  $(".month-filter, .patient-filter").on("change", function(){
    window.location.href = '/process_entries/?month=' + $(".month-filter").val() + '&patient=' + $(".patient-filter").val();
  });
  $(".absence-patient-filter").on("change", function(){
    window.location.href = '/absences/?patient=' + $(".absence-patient-filter").val();
  });

  $(".current-patient-filter").on("change", function(){
    window.location.href = '/process_entries/current/' + $(".current-patient-filter").val();
  });

  $(window).on( 'scroll', function(){
    
    if ($(window).scrollTop() > 300 && $(window).scrollTop() < $(".processes-table").position().top + $(".processes-table").outerHeight() -100 ){
      $(".ghost-header").css("top", $(window).scrollTop() + 150 + "px");
      $(".ghost-header").removeClass("d-none");
    }
    else{
      $(".ghost-header").addClass("d-none");
    }
  });

  $('form:not(.new-session-login)').on('keyup keypress', function(e) {
    var keyCode = e.keyCode || e.which;
    if (keyCode === 13) { 
      e.preventDefault();
      return false;
    }
  });
  
  //$(".form-control").on("dblclick", function(){
  $('.processes-table').on('click', '.form-control', function() {
    let value= parseInt($(this).val()) || 0;
    value = value + 1;
    if (value > 3){
      value = 0;
    }

    $(this).val(value);
    $(this).change();
  });

  $(document).on("contextmenu", ".processes-table .form-control", function(e){
    e.preventDefault();
    let value= parseInt($(this).val()) - 1  || 0;
    if(value <= 0){ value = 0; }
    $(this).val(value);
    $(this).change();
    return false;
  });

  $('.processes-table').on('change', '.form-control', function(e) {
    let value= parseInt($(this).val()) || 0;
    if (value > 0){
      $(this).addClass("bg-light-gray")
    }
    else{
      $(this).removeClass("bg-light-gray")
    }

    let total_value = 0;
    $('.processes-table input[name="' + $(this).attr("name") +'"]').each(function(){
      total_value += parseInt($(this).val());
    });
    let name = $(this).attr("name").replace("process_entry[][", "").replace("]", "");
    $("."+name).find(".total-value").val(total_value);
    $("."+name).find(".total-value").change();

    
  });

  $(".add-process-entry-btn").on("click", function(){
    day ++;
    if(day <= 31){
      let exp_value = $(parent).find(".exp-value").val();
      var template = $(".process-entry-template").clone();
      $(template).removeClass("process-entry-template");
      $(template).find(".template-day").val(day);
  
      $(".processes-table").append(template);
      
      $(template).find("input:not(.template-day)").each(function(){
        let name = $(this).attr("name").replace("process_entry[][", "").replace("]", "");
        $("."+name).find(".exp-value.incrementable").trigger("increment");
        $(this).change();
      });
    }
  });

  $(".indicators-table").on("increment", ".exp-value", function(){
    let exp_value = parseInt($(this).val());
    $(this).val(exp_value + 1);
  });
  $(".indicators-table .exp-value, .indicators-table .total-value").on("change", function(){
    let parent = $(this).parents("tr:first");
    let exp_value = $(parent).find(".exp-value").val();
    let total_value = $(parent).find(".total-value").val();
    let percent_value = (total_value / exp_value * 100);
    $(parent).find(".percent-value").val((~~percent_value).toFixed(2) + "%");
  });
  
  $('input[type="checkbox"]').change(function(){
    //this.value = (Number(this.checked));
  });


  
  $(".div-toggle .form-check-input").on("change", function(){
    console.log("div-toggle change");
    $(".processes").toggleClass("d-none")
    $(".indicators").toggleClass("d-none")

    if($(this).prop("checked")){
      $("#for-print").text(
        "@media print {" +
            "@page {" +
                "size: portrait;" +
            "}" +
        "}"
      );
    }
    else{
      $("#for-print").text(
        "@media print {" +
            "@page {" +
                "size: landscape;" +
            "}" +
        "}"
      );
    }
  });
});